<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#E6FFBD;"
      d="M360.585,0.553L18.618,120.993l111.709,0.003l265.584-48.164L372.458,6.241
	C370.751,1.392,365.435-1.155,360.585,0.553z"
    />
    <path
      style="fill:#D2FF87;"
      d="M450.159,55.313c-0.96-5.05-5.833-8.367-10.885-7.407L55.855,121.019l406.792-0.027L450.159,55.313z"
    />
    <path
      style="fill:#9B735A;"
      d="M474.764,511.978H18.618C8.335,511.978,0,503.643,0,493.36V139.615
	c0-10.283,8.335-18.618,18.618-18.618h456.145c10.283,0,18.618,8.335,18.618,18.618V493.36
	C493.382,503.643,485.047,511.978,474.764,511.978z"
    />
    <g>
      <path
        style="fill:#8C5F50;"
        d="M456.145,148.924h-409.6v-9.309c0-10.283,8.336-18.618,18.618-18.618H18.618
		C8.336,120.996,0,129.332,0,139.615V493.36c0,10.282,8.336,18.618,18.618,18.618h46.545c-10.282,0-18.618-8.336-18.618-18.618
		v-9.309h409.6c5.146,0,9.309-4.168,9.309-9.309V158.233C465.455,153.093,461.291,148.924,456.145,148.924z M446.836,465.433H46.545
		V167.542h400.291V465.433z"
      />
      <path
        style="fill:#8C5F50;"
        d="M502.691,363.033h-74.473c-25.706,0-46.545-20.84-46.545-46.545l0,0
		c0-25.706,20.84-46.545,46.545-46.545h74.473c5.141,0,9.309,4.168,9.309,9.309v74.473C512,358.866,507.832,363.033,502.691,363.033
		z"
      />
    </g>
    <g>
      <path
        style="fill:#7D4B41;"
        d="M27.927,465.433H0v18.618h27.927c5.146,0,9.309-4.168,9.309-9.309
		C37.236,469.601,33.073,465.433,27.927,465.433z"
      />
      <path
        style="fill:#7D4B41;"
        d="M83.782,484.051H65.164c-5.146,0-9.309-4.168-9.309-9.309c0-5.141,4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309C93.091,479.883,88.927,484.051,83.782,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M27.927,148.924H0v18.618h27.927c5.146,0,9.309-4.168,9.309-9.309S33.073,148.924,27.927,148.924z"
      />
      <path
        style="fill:#7D4B41;"
        d="M83.782,167.542H65.164c-5.146,0-9.309-4.168-9.309-9.309s4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309S88.927,167.542,83.782,167.542z"
      />
      <path
        style="fill:#7D4B41;"
        d="M139.636,484.051h-18.618c-5.146,0-9.309-4.168-9.309-9.309c0-5.141,4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309C148.945,479.883,144.782,484.051,139.636,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M139.636,167.542h-18.618c-5.146,0-9.309-4.168-9.309-9.309s4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309S144.782,167.542,139.636,167.542z"
      />
      <path
        style="fill:#7D4B41;"
        d="M195.491,484.051h-18.618c-5.146,0-9.309-4.168-9.309-9.309c0-5.141,4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309C204.8,479.883,200.637,484.051,195.491,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M195.491,167.542h-18.618c-5.146,0-9.309-4.168-9.309-9.309s4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309S200.637,167.542,195.491,167.542z"
      />
      <path
        style="fill:#7D4B41;"
        d="M251.345,484.051h-18.618c-5.146,0-9.309-4.168-9.309-9.309c0-5.141,4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309C260.655,479.883,256.491,484.051,251.345,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M251.345,167.542h-18.618c-5.146,0-9.309-4.168-9.309-9.309s4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309S256.491,167.542,251.345,167.542z"
      />
      <path
        style="fill:#7D4B41;"
        d="M307.2,484.051h-18.618c-5.146,0-9.309-4.168-9.309-9.309c0-5.141,4.164-9.309,9.309-9.309H307.2
		c5.146,0,9.309,4.168,9.309,9.309C316.509,479.883,312.346,484.051,307.2,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M307.2,167.542h-18.618c-5.146,0-9.309-4.168-9.309-9.309s4.164-9.309,9.309-9.309H307.2
		c5.146,0,9.309,4.168,9.309,9.309S312.346,167.542,307.2,167.542z"
      />
      <path
        style="fill:#7D4B41;"
        d="M363.055,484.051h-18.618c-5.146,0-9.309-4.168-9.309-9.309c0-5.141,4.164-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309C372.364,479.883,368.2,484.051,363.055,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M363.055,167.542h-18.618c-5.146,0-9.309-4.168-9.309-9.309s4.164-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309S368.2,167.542,363.055,167.542z"
      />
      <path
        style="fill:#7D4B41;"
        d="M418.909,484.051h-18.618c-5.146,0-9.309-4.168-9.309-9.309c0-5.141,4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309C428.218,479.883,424.055,484.051,418.909,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M418.909,167.542h-18.618c-5.146,0-9.309-4.168-9.309-9.309s4.163-9.309,9.309-9.309h18.618
		c5.146,0,9.309,4.168,9.309,9.309S424.055,167.542,418.909,167.542z"
      />
      <path
        style="fill:#7D4B41;"
        d="M456.145,484.051c-5.146,0-9.309-4.168-9.309-9.309v-18.618c0-5.141,4.163-9.309,9.309-9.309
		s9.309,4.168,9.309,9.309v18.618C465.455,479.883,461.291,484.051,456.145,484.051z"
      />
      <path
        style="fill:#7D4B41;"
        d="M456.145,186.16c-5.146,0-9.309-4.168-9.309-9.309v-18.618c0-5.141,4.163-9.309,9.309-9.309
		s9.309,4.168,9.309,9.309v18.618C465.455,181.992,461.291,186.16,456.145,186.16z"
      />
      <path
        style="fill:#7D4B41;"
        d="M456.145,242.015c-5.146,0-9.309-4.168-9.309-9.309v-18.618c0-5.141,4.163-9.309,9.309-9.309
		s9.309,4.168,9.309,9.309v18.618C465.455,237.846,461.291,242.015,456.145,242.015z"
      />
      <path
        style="fill:#7D4B41;"
        d="M456.145,428.196c-5.146,0-9.309-4.168-9.309-9.309v-18.618c0-5.141,4.163-9.309,9.309-9.309
		s9.309,4.168,9.309,9.309v18.618C465.455,424.028,461.291,428.196,456.145,428.196z"
      />
    </g>
    <circle style="fill:#FFDC64;" cx="428.218" cy="316.486" r="18.618" />
    <path
      style="fill:#8C5F50;"
      d="M244.364,307.63v-62.623c24.612,2.797,41.891,14.097,41.891,24.354
	c0,6.427,5.21,11.636,11.636,11.636c6.427,0,11.636-5.21,11.636-11.636c0-24.577-27.828-44.266-65.164-47.703v-7.569
	c0-6.427-5.21-11.636-11.636-11.636s-11.636,5.21-11.636,11.636v7.569c-37.335,3.437-65.164,23.126-65.164,47.703
	c0,32.986,29.891,45.974,65.164,55.985v62.623c-24.612-2.797-41.891-14.097-41.891-24.354c0-6.427-5.21-11.636-11.636-11.636
	s-11.636,5.21-11.636,11.636c0,24.577,27.828,44.266,65.164,47.703v7.569c0,6.427,5.21,11.636,11.636,11.636
	s11.636-5.21,11.636-11.636v-7.569c37.335-3.437,65.164-23.126,65.164-47.703C309.527,330.629,279.637,317.64,244.364,307.63z
	 M179.2,269.36c0-10.256,17.279-21.556,41.891-24.354v56.058C190.106,291.545,179.2,282.919,179.2,269.36z M244.364,387.969v-56.058
	c30.986,9.521,41.891,18.146,41.891,31.704C286.255,373.872,268.976,385.172,244.364,387.969z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
